<template>
  <div class="bg-gray-300 pb-3 h-screen md:h-full">  <!-- J'ai un souci avec h-screen car ça ne couvre que l'écran, pas le reste si j'ai besoin de descendre -->
    <!-- Barre de login -->
    <div class="bg-white flex flex-row-reverse p-1 mb-4 shadow-md">
      <button @click="logout" class="bg-amber-500 text-white px-4 py-2 mr-2 rounded hover:bg-orange-500">Se déconnecter</button><br><br>
    </div>

    <!-- Gestion des erreurs -->
    <div v-if="error.message" class="bg-red-200 mx-4 p-4 rounded mt-4 mb-8 shadow">
      <div>
        <span class="font-bold text-lg">Message:</span><span class="text-gray-600 pl-1">{{ error.message }}</span>
      </div>
      <div>
        <span class="font-bold text-lg">Erreur:</span><span class="text-gray-600 pl-1">{{ error.error }}</span>
      </div>
    </div>
    <!-- Fin de la gestion des erreurs -->

    <div class="bg-white mx-4 p-4 rounded shadow">
      <!-- Alarme -->
      <div class="flex flex-col md:flex-row md:justify-between items-center mb-3">
        <div>
          <span class="font-bold text-lg">Alarme:</span>
            <span v-if="alarm.Alarm === 'stop'" class="text-gray-600 pl-1">À l'arrêt</span>
            <span v-if="alarm.Alarm === 'start'" class="text-gray-600 pl-1">En marche</span>
        </div>
        <div>
          <button @click="startStopAlarm('start')" v-if="alarm.Alarm === 'stop' && !alarm.Partial" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">Démarrer l'alarme</button>
          <button @click="startStopAlarm('stop')" v-if="alarm.Alarm === 'start'" class="m-1 shadow leading-tight bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">Arrêter l'alarme</button>
          <button v-if="alarm.Partial" class="m-1 shadow leading-tight bg-gray-300 text-white px-4 py-2 rounded cursor-not-allowed">Démarrer l'alarme</button>
        </div>
      </div>
      <!-- Fin alarme -->

      <!-- Sirènes -->
      <div class="flex flex-col md:flex-row md:justify-between items-center mb-3">
        <div>
          <span class="font-bold text-lg">Sirènes:</span>
            <span v-if="!alarm.Siren" class="text-gray-600 pl-1">À l'arrêt</span>
            <span v-if="alarm.Siren" class="text-gray-600 pl-1">En marche</span>
        </div>
        <div>
          <button @click="startStopSiren('start')" v-if="!alarm.Siren && !alarm.Partial" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">Démarrer les sirènes</button>
          <button @click="startStopSiren('stop')" v-if="alarm.Siren" class="m-1 shadow leading-tight bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">Arrêter les sirènes</button>
        </div>
      </div>
      <!-- Fin sirènes -->

      <!-- Alarme partielle -->
      <!-- <div class="flex flex-col md:flex-row md:justify-between items-center mb-3">
        <div>
          <span class="font-bold text-lg">Alarme partielle:</span>
            <span v-if="!alarm.Partial" class="text-gray-600 pl-1">À l'arrêt</span>
            <span v-if="alarm.Partial" class="text-gray-600 pl-1">En marche</span>
        </div>
        <div>
          <button @click="startStopPartial('start')" v-if="!alarm.Partial && alarm.Alarm === 'stop'" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">Démarrer l'alarme partielle</button>
          <button @click="startStopPartial('stop')" v-if="alarm.Partial" class="m-1 shadow leading-tight bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">Arrêter l'alarme partielle</button>
          <button v-if="alarm.Alarm === 'start'" class="m-1 shadow leading-tight bg-gray-300 text-white px-4 py-2 rounded cursor-not-allowed">Démarrer l'alarme partielle</button>
        </div>
      </div> -->
      <!-- Fin Alarme partielle -->

      <!-- Vacances -->
      <!-- <div class="flex flex-col md:flex-row md:justify-between items-center">
        <div>
          <span class="font-bold text-lg">Mode vacances:</span>
            <span v-if="!alarm.Holidays" class="text-gray-600 pl-1">À l'arrêt</span>
            <span v-if="alarm.Holidays" class="text-gray-600 pl-1">En marche</span>
        </div>
        <div>
          <button @click="startStopHolidays('start')" v-if="!alarm.Holidays" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">Démarrer le mode vacances</button>
          <button @click="startStopHolidays('stop')" v-if="alarm.Holidays" class="m-1 shadow leading-tight bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">Arrêter le mode vacances</button>
        </div>
      </div> -->
      <!-- Fin vacances -->
    </div>

    <!-- État des caméras -->
    <!--  -->
    <!-- rajouter ? avec un numéro aléatoire derrière -->
    <!-- autrement quand j'allume, j'éteins et je rallume, ça ne marche plus -->
    <!-- La fonction random est déjà faite -->
    <!--  -->
    <div class="bg-white mx-4 p-4 rounded shadow mt-4">
      <div v-for="(obj, idx) in whereList" :key="idx" class="mb-3 md:flex md:justify-between">
        <div class="flex flex-col">
          <div class="flex flex-col">
            <div><span class="font-bold text-lg">Zone:</span><span class="text-gray-600 ml-2">{{ obj.Name }}</span></div>
            <div><span class="font-bold text-lg">Détection:</span>
              <span v-if="obj.Alarm === 'stop'" class="text-gray-600 ml-2">À l'arrêt</span>
              <span v-if="obj.Alarm === 'start'" class="text-gray-600 ml-2">En marche</span>
            </div>
            <div><span class="font-bold text-lg">Réglage:</span>
              <span v-if="obj.Stream === 'stop'" class="text-gray-600 ml-2">À l'arrêt</span>
              <span v-if="obj.Stream === 'start'" class="text-gray-600 ml-2">En marche</span>
            </div>
          </div>
          <div class="flex items-center justify-center mt-2 md:mt-0">
            <button @click="startStopCamera('start', 'motion', obj.Where)" v-if="obj.Alarm === 'stop' && obj.Stream === 'stop'" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-800">Détection</button>
            <button @click="startStopCamera('start', 'stream', obj.Where)" v-if="obj.Stream === 'stop' && obj.Alarm === 'stop'" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-800">Réglages</button>
            <button @click="startStopCamera('stop', 'motion', obj.Where)" v-if="obj.Alarm !== 'stop'" class="m-1 shadow leading-tight bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">Arrêter la détection</button>
            <button @click="startStopCamera('stop', 'stream', obj.Where)" v-if="obj.Stream !== 'stop'" class="m-1 shadow leading-tight bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">Arrêter les réglages</button>
          </div>
        </div>
        <div class="flex">
          <!-- Rajouter le ? avec le nombre aléatoire qui se trouve dans randomNumber -->
          <!-- <img v-if="obj.Stream === 'start'" :src="`${axios.defaults.baseURL}/` + obj.Name"> -->
          <img v-if="obj.Stream === 'start'" :src="`${axios.defaults.baseURL}/` + obj.Name + '?time=' + randomNumber">
        </div>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlarmComp',
  props: {
  },
  data() {
    return {
      config: {
        exp: '',
        token: '',
      },
      alarm: {},
      deltaTime: undefined,
      timeout: undefined,
      error: {},
      whereList: [],
      randomNumber: 0,
    };
  },
  methods: {
    getAlarmState() {
      this.axios.get('/api/v1/getState')
        .then((response) => {
          console.log(response.data)

          this.alarm = response.data.state
          this.whereList = response.data.whereList

          this.alarm.Alarm = this.alarm.Alarm ? 'start' : 'stop'
        })
        .catch((err) => {
          this.error = {
            'message': "Impossible d'obtenir l'état de l'alarme",
            'error': err,
          }
        })
    },
    getRandomNumber() {
      let min = 10
      let max = 99999999
      this.randomNumber = Math.floor(Math.random() * (max - min) + min)
    },
    startStopAlarm(action) {
      this.axios.get('/api/v1/' + action + 'Alarm')
        .then((response) => {
          this.alarm = response.data.state
          this.whereList = response.data.whereList

          this.alarm.Alarm = this.alarm.Alarm ? 'start' : 'stop'
        })
        .catch((err) => {
          let msg = undefined
          if (action === 'start') {
            msg = 'de démarrer'
          } else if (action === 'stop') {
            msg = "d'arrêter"
          }

          this.error = {
            'message': 'Impossible ' + msg + " l'alarme",
            'error': err,
          }
        })
    },
    startStopSiren(action) {
      this.axios.get('/api/v1/' + action + 'Siren')
        .then((response) => {
          console.log(response)

          this.alarm = response.data.state
          this.whereList = response.data.whereList

          this.alarm.Alarm = this.alarm.Alarm ? 'start' : 'stop'
        })
        .catch((err) => {
          let msg = undefined
          if (action === 'start') {
            msg = 'de démarrer'
          } else if (action === 'stop') {
            msg = "d'arrêter"
          }

          this.error = {
            'message': 'Impossible ' + msg + " les sirènes",
            'error': err,
          }
        })
    },
    // startStopPartial(action) {
    //   this.axios.get('/api/v1/' + action + 'Partial')
    //     .then((response) => {
    //       this.alarm = response.data.state
    //       this.whereList = response.data.whereList
    //     })
    //     .catch((err) => {
    //       let msg = undefined
    //       if (action === 'start') {
    //         msg = 'de démarrer'
    //       } else if (action === 'stop') {
    //         msg = "d'arrêter"
    //       }

    //       this.error = {
    //         'message': 'Impossible ' + msg + " le mode partiel",
    //         'error': err,
    //       }
    //     })
    // },
    // startStopHolidays(action) {
    //   this.axios.get('/api/v1/' + action + 'Holidays')
    //     .then((response) => {
    //       this.alarm = response.data.state
    //       this.whereList = response.data.whereList
    //     })
    //     .catch((err) => {
    //       let msg = undefined
    //       if (action === 'start') {
    //         msg = 'de démarrer'
    //       } else if (action === 'stop') {
    //         msg = "d'arrêter"
    //       }

    //       this.error = {
    //         'message': 'Impossible ' + msg + " le mode vacances",
    //         'error': err,
    //       }
    //     })
    // },
    startStopCamera(action, type, where) {
      type = type.charAt(0).toUpperCase() + type.slice(1)

      this.getRandomNumber()

      this.axios.get('/api/v1/' + action + type + 'OneCamera', {
        params: {
          where,
        }
      })
        .then((response) => {
          this.alarm = response.data.state
          this.whereList = response.data.whereList

          this.alarm.Alarm = this.alarm.Alarm ? 'start' : 'stop'
        })
        .catch((err) => {
          let msg = undefined
          if (action === 'start') {
            msg = 'de démarrer'
          } else if (action === 'stop') {
            msg = "d'arrêter"
          }

          this.error = {
            'message': 'Impossible ' + msg + ' la caméra ' + where,
            'err': err,
          }
        })
    },
    callRefreshToken() {
      this.refreshToken();
      if (this.deltaTime > 0) {
        this.timeout = setTimeout(this.callRefreshToken, this.deltaTime)
      }
    },
    loadConfigAtMount() {
      if (localStorage.config) {
        const config = JSON.parse(localStorage.config)
        const now = Math.floor(Date.now() / 1000)

        if (config.exp && (config.exp > now)) {
          this.config = config
          this.deltaTime = (config.exp - now - 5) * 1000
          if (this.deltaTime <= 0) {
            this.$router.push({ name: 'Auth' })
          } else {
            if (!this.axios.defaults.headers.common.Authorization) {
              this.axios.defaults.headers.common.Authorization = String.prototype.concat('Bearer ', config.token)
            }

            this.timeout = setTimeout(this.callRefreshToken, this.deltaTime)
          }
        } else {
          clearTimeout(this.timeout)
          this.$router.push({ name: 'Auth' })
        }
      } else {
        clearTimeout(this.timeout)
        this.$router.push({ name: 'Auth' })
      }
    },
    logout() {
      delete localStorage.config
      clearTimeout(this.timeout)
      this.axios.defaults.headers.common.Authorization = ''
      this.$router.push({ name: 'Auth' })
    },
    refreshToken() {
      this.axios.get('/api/v1/refresh')
        .then((response) => {
          const { token } = response.data
          this.config.token = token
          this.axios.defaults.headers.common.Authorization = String.prototype.concat('Bearer ', token)

          // Check jwt's expiration
          const jwtPayloadSplitted = token.split('.')
          const payload = JSON.parse(atob(jwtPayloadSplitted[1]))
          if (payload.exp) {
            const now = Math.floor(Date.now() / 1000)

            this.config.exp = payload.exp
            this.deltaTime = (payload.exp - now - 5) * 1000
          }

          // Save new config to localStorage
          localStorage.config = JSON.stringify(this.config)
        })
        .catch((err) => {
          console.log('erreur :', err)

          clearTimeout(this.timeout)
          this.axios.defaults.headers.common.Authorization = ''
          delete localStorage.config
          this.$router.push({ name: 'Auth' })
        });
    },
  },
  mounted() {
    this.loadConfigAtMount()
    this.getRandomNumber()
    this.getAlarmState()
  },
};
</script>

<style lang="css" scoped>
</style>